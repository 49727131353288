import { __decorate } from "tslib";
import { Vue, Prop, Emit, Ref, Watch, } from 'vue-property-decorator';
import { LabelService } from '@/sevices';
import * as Utils from '@/common/utils';
import { TagType, OperateType, ShareType } from '../../../../typings/organization/label-manage';
export default class UpdateGroupBase extends Vue {
    constructor() {
        super(...arguments);
        this.OperateType = OperateType;
        this.visible = false;
        this.loading = false;
        this.modalTitle = '...';
        this.submiting = false;
        this.isSelectedParent = false;
        this.form = {
            name: '',
            org: undefined,
        };
        this.orgList = [];
        this.rules = {
            name: [
                { required: true, message: '分类名称不能为空', trigger: 'blur' },
                {
                    validator: (value) => /^[\u4e00-\u9fa5_a-zA-Z0-9\-()]{0,30}$/.test(value),
                    message: '请输入中英文、数字及英文符号”()”、“-”、“_”，最大长度为30个字',
                    trigger: 'blur',
                },
            ],
            org: [
                { required: true, message: '上级组织不能为空', trigger: 'blur' },
            ],
        };
    }
    get routeName() {
        return this.$route.name;
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    async comfirm() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        try {
            this.submiting = true;
            this.operateType === OperateType.Add
                ? await this.addGroup()
                : await this.updateGroup();
        }
        finally {
            this.submiting = false;
        }
    }
    async addGroup() {
        const params = {
            orgId: this.form.org,
            name: this.form.name,
            labelType: TagType.Group,
            shareType: this.routeName === 'labels' ? ShareType.Sys : ShareType.Org,
        };
        const res = await LabelService.addLabelOrGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
        }
        this.reloadTree({
            data: res.data,
            type: 'add',
        });
        this.reload();
        this.cancel();
    }
    reloadTree(data) {
        return data;
    }
    reload() { }
    async updateGroup() {
        const params = {
            labelType: TagType.Group,
            name: this.form.name,
            id: this.groupDetail.id,
        };
        const res = await LabelService.upDateLabelOrGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
            return;
        }
        const data = res.data;
        this.$message.success('更新成功');
        this.reloadTree({
            data: data,
            type: 'update',
        });
        this.reload();
        this.cancel();
    }
    created() {
    }
    cancel() {
        this.$emit('input', false);
    }
    async getDetail(id) {
        const { success, data, errMessage } = await LabelService.getLabelOrGroupDetail(id);
        if (!success) {
            this.$message.error(errMessage);
        }
        return data || {};
    }
    async getOrgAndGroup(params) {
        const res = await LabelService.getOrgAndGroup(params);
        if (!res.success) {
            this.$message.error(res.errMessage);
        }
        return res.data;
    }
}
__decorate([
    Ref()
], UpdateGroupBase.prototype, "cform", void 0);
__decorate([
    Prop()
], UpdateGroupBase.prototype, "value", void 0);
__decorate([
    Prop()
], UpdateGroupBase.prototype, "groupDetail", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], UpdateGroupBase.prototype, "operateType", void 0);
__decorate([
    Watch('form', { deep: true })
], UpdateGroupBase.prototype, "onFormChange", null);
__decorate([
    Emit('reloadTree')
], UpdateGroupBase.prototype, "reloadTree", null);
__decorate([
    Emit('reload')
], UpdateGroupBase.prototype, "reload", null);
