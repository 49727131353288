import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { OperateType } from '../../../../typings/organization/label-manage';
import UpdateGroupBase from './update-group-base';
let UpdateGroup = class UpdateGroup extends UpdateGroupBase {
    async comfirm() {
        super.comfirm();
    }
    cancel() {
        super.cancel();
    }
    onValueChange(v) {
        if (v) {
            this.modalTitle = (this.operateType === OperateType.Add ? '添加' : '编辑') + '分类';
            const groupData = this.groupDetail;
            if (this.operateType === OperateType.Edit) {
                this.loading = true;
                this.getDetail(groupData.id).then((detail) => {
                    this.form.name = detail.name;
                    this.isSelectedParent = true;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
        else {
            this.form = {
                name: '',
            };
            this.isSelectedParent = false;
        }
    }
};
__decorate([
    Watch('value')
], UpdateGroup.prototype, "onValueChange", null);
UpdateGroup = __decorate([
    Component({
        name: 'UpdateGroup',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], UpdateGroup);
export default UpdateGroup;
